<template>
  <WeContainer v-if="ready">
    <h5 class="custom-card-title">
      <i class="fas fa-check"></i> <span>KARGO SİGORTA ŞİRKETLERİ</span>
    </h5>
    <hr />
    <WeTable
      v-bind:index="false"
      v-bind:data="cargoinsurance.list"
      v-bind:columns="columns"
      v-bind:actions="tableActions"
      v-on:on-action="onAction"
      v-on:on-switch="onSwitch"
    ></WeTable>

    <!-- Detail -->
    <WeModal v-if="showModal" v-on:close="onClose" v-bind:title="detail.name">
      <div slot="body">
        <template v-if="!loading">
          <div class="row mb-3">
            <div
              class="col-12"
              v-for="el in detail.elements"
              v-bind:key="el.name"
            >
              <div class="form-group">
                <WeRowInput
                  v-bind:form="el.form"
                  size="sm"
                  v-bind:mini-description="el.name"
                  v-bind:name="el.code"
                  v-bind:label="el.title"
                  v-model="el.value"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <WeRowInput
                  size="sm"
                  name="insurance_fixed_cost"
                  label="Sigorta Sabit Masrafı"
                  v-bind:filter-number="true"
                  v-model="detail.insurance_fixed_cost"
                />
              </div>
            </div>
          </div>
          <div class="text-center">
            <WeSubmitButton
              update="1"
              v-bind:submit-status="submit"
              v-on:submit="onSave"
            />
          </div>
        </template>
        <div class="position-relative" style="min-height: 250px" v-else>
          <WeLoading />
        </div>
      </div>
    </WeModal>
    <!-- ./Detail -->
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "List",
  data() {
    return {
      ready: false,
      detail: null,
      showModal: false,
      submit: false,
      loading: false,
      items: [
        {
          name: "Brinks",
          code: "brinks",
          elements: [
            {
              name: "username",
              value: null,
              type: "text",
              form: "input",
              title: "Kullanıcı Adı",
            },
            {
              name: "password",
              value: null,
              form: "input",
              type: "text",
              title: "Şifre",
            },
            {
              name: "branch",
              value: null,
              form: "input",
              type: "text",
              title: "Branch",
            },
          ],
        },
      ],
      tableActions: [
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary",
          action: "edit",
        },
      ],
      columns: [
        { name: "name", th: "Marka Adı", type: "string", width: "70%" },
        { name: "is_active", th: "Durum", type: "boolean" },
      ],
    };
  },
  methods: {
    ...mapActions("cargoinsurance", [
      "update",
      "getList",
      "getById",
      "isActive",
    ]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    onAction(data) {
      if (data.key == "edit") {
        this.onEdit(data.row);
      }
    },
    onEdit(row) {
      let itemDetail = this.items.find((item) => item.code === row.code);
      itemDetail.id = row.id;

      if (itemDetail) {
        this.loading = true;
        this.showModal = true;
        this.detail = helper.clone(itemDetail);

        this.getById({
          id: row.id,
          onSuccess: (result) => {
            const resultData = result.data.item;
            const config = resultData.config;

            this.detail.elements.map((item) => {
              if (config && Object.keys(config).length) {
                Object.entries(config).forEach(([configKey, configValue]) => {
                  if (item.name == configKey) {
                    item.value = configValue;
                  }
                });
              }
            });

            this.detail.insurance_fixed_cost = resultData.insurance_fixed_cost;
          },
          onFinish: () => {
            this.loading = false;
          },
        });
      }
    },
    onSave() {
      if (this.detail) {
        this.submit = true;
        let elements = helper.clone(this.detail.elements);
        let requestData = {
          insurance_fixed_cost: this.detail.insurance_fixed_cost,
          config: {},
        };

        elements.forEach((item) => {
          requestData.config[item.name] = item.value;
        });

        this.update({
          id: this.detail.id,
          form: requestData,
          onSuccess: (result) => {
            if (result && result.data && result.data.status !== "error") {
              this.showAlert(result, this.onClose);
            } else {
              this.showAlert(result);
            }
          },
          onError: () => {
            this.onClose;
          },
          onFinish: () => {
            this.submit = false;
          },
        });
      }
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    onSwitch(data) {
      this.isActive({
        form: data,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$toast.success("Durum Güncellendi");
          } else {
            this.$toast.error("Durum Güncellenemedi");
          }
        },
        onError: (error) => {
          this.$toast.error("Durum Güncellenemedi");
        },
      });
    },
    onClose() {
      this.showModal = false;
      this.detail = null;
    },
  },
  computed: {
    ...mapState(["cargoinsurance", "shared"]),
  },
  mounted() {
    this.getList({
      onFinish: () => {
        this.ready = true;
      },
    });
  },
};
</script>
